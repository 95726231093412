import * as React from 'react';

import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';
import { useI18nArray } from 'src/app/hooks';

const seo: SeoObject = {
  title: 'About us - Hey Holiday',
};

export const AboutPage = (props: AppProps) => {
  const { i18nItems: content } = useI18nArray<string>(
    'staticLocales:pages./about.content array',
  );
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./about.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./about.link label'} />}
      {...{ ...props, seo }}
    >
      {content
        ? content.map((val, idx) => {
            return (
              <div>
                {idx === 0 ? (
                  <h2 className='text-p1 md:text-h4 font-semibold mb-4'>
                    {val}
                  </h2>
                ) : (
                  <p className='mb-4'>
                    {val}
                  </p>
                )}
              </div>
            );
          })
        : null}
    </AppPageLayout>
  );
};

export default AboutPage;
